import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';
import ar_EG from './assets/i18n/ar-eg.json';
import pt_BR from './assets/i18n/pt-br.json';
import de from './assets/i18n/de.json';
import id from './assets/i18n/id.json';
import nl from './assets/i18n/nl.json';
import es_MX from './assets/i18n/es-mx.json';

i18n.use(initReactI18next).init({
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  keySeparator: false,
  supportedLngs: ['en', 'fr', 'ar-EG', 'pt-BR', 'de', 'id', 'nl', 'es-MX'],
  resources: {
    en: { translation: en.master },
    fr: { translation: fr.master },
    'ar-EG': { translation: ar_EG.master },
    'pt-BR': { translation: pt_BR.master },
    de: { translation: de.master },
    id: { translation: id.master },
    nl: { translation: nl.master },
    'es-MX': { translation: es_MX.master },
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
