import { createSlice } from '@reduxjs/toolkit';

const initialState = null;
export const selectors = {
  getQuestionnaire(state) {
    return state.questionnaire;
  },
};

const slice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    returnToDefaultValues(state, { payload }) {
      return initialState;
    },
    setQuestionnaire(state, { payload }) {
      console.log('setQuestionnaire', { payload });
      return payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
