import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, FetchProvider } from 'lib/providers';
import Routing from 'routing';
import store, { persistor } from './store/index';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './i18n';
import 'lib/icons/all.js';

export default function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <FetchProvider>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider autoHideDuration={3000}>
                <DndProvider backend={HTML5Backend}>
                  <Routing />
                </DndProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </FetchProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
